import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@/layout"
import Img from "gatsby-image"
import styled from "styled-components"

import {
  Box,
  CTA,
  SEO,
  Width,
  Heading,
  Text,
  SmallWave,
  MentorAlert,
  Coupon,
  Relationships,
  Grow,
  List,
  ListItem,
} from "@/components/index"
import { color } from "@/theme/index"
import { trackAffiliate } from "@/utils/affiliateTracking"
import { useLocation } from "@reach/router"

export default function Prep({ data }) {
  const location = useLocation()
  trackAffiliate(location)
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Prepare for the Sonora Guitar Intensive"
        description="A 10-week prep course that will get you ready for the intensive"
        noindex={true}
      />
      <Box
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt="9.6rem"
      >
        {/* Accelerated Learning */}
        <Box>
          <Width pt="8rem" pb="3rem">
            <Box
              width={["100%", 10 / 12, 8 / 12, 8 / 12]}
              mx="auto"
              mb="5rem"
              textAlign="center"
            >
              <SmallWave display="block" mx="auto" mb="1.2rem" />
              <Heading mb="1.6rem">Sonora Prep Program</Heading>
              <Text
                fontSize="14px"
                fontWeight="bold"
                color={color.tanzanite}
                mb=".6rem"
              >
                10-WEEK COURSE
              </Text>

              <Text mt="3.6rem">
                Sonora's 6-week online prep course is designed to take
                participants with little-to-some guitar experience to
                intensive-ready under the instruction of world-class mentors.
              </Text>

              <Text mt="2em">
                <em>
                  <strong>Next Cohort Begins: 4/1/24</strong>
                </em>
              </Text>
              <Text
                fontSize="14px"
                fontWeight="bold"
                color={color.tanzanite}
                mb=".6rem"
              >
                LIMITED TO 30 STUDENTS
              </Text>
            </Box>
            <Box display={["block", null, "flex", null]}>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                borderRadius=".6rem"
                textAlign="center"
                mb={["2.4rem", null, "0"]}
                bg="#FFF7F4"
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  display="flex"
                  mb="1.8rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <Grow mr="0.8rem" height={30} width={30} />
                  <Text fontWeight="bold" color="amethyst">
                    Structured for success
                  </Text>
                </Box>
                <Text level={2}>
                  Prep’s curriculum is organized to plant the seeds for success
                  in your playing.
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                textAlign="center"
                mb={["2.4rem", null, "0"]}
                bg={color.opal}
                borderRadius=".6rem"
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  display="flex"
                  mb="1.8rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <Relationships height={30} width={38} mr="0.5rem" />
                  <Text fontWeight="bold" color="amethyst">
                    Engaging Group Classes
                  </Text>
                </Box>
                <Text level={2}>
                  Learn from our very own mentor staff as you develop your
                  foundation and grow as a musician.
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                textAlign="center"
                bg={color.agate}
                borderRadius=".6rem"
                ml={["auto", null, 0, null]}
                mr={["auto", null, 0, null]}
              >
                <Box
                  display="flex"
                  mb="0.6rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <MentorAlert mr="0.8rem" height={40} width={41} />
                  <Text fontWeight="bold" color="amethyst">
                    Community Collaboration
                  </Text>
                </Box>
                <Text level={2}>
                  Interact with others on the same path through Zoom and our
                  Facebook group
                </Text>
              </Box>
            </Box>
            {/* <Box
              width={["100%", "80%", 6 / 12, null]}
              p="2.4rem"
              textAlign="center"
              bg={color.amethyst}
              borderRadius=".6rem"
              mt="1em"
              mx="auto"
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={["center", null, null]}
              >
                <Coupon mr="1rem" height={35} width={41} />
                <Text fontWeight="bold" color="white">
                  Tuition Discount
                </Text>
              </Box>
              <Text level={2} color="white">
                After you finish Prep, if you join our Intermediate Intensive
                Programs, the cost of Prep is credited towards your Tuition.
              </Text>
              <h4>
                <Dollar> </Dollar>
                <Number>-$500</Number>
              </h4>
            </Box> */}
            <Box width="100%" mt="3.6rem" mx="auto" textAlign="center">
              <Text level={1}>Interested in learning more?</Text>
              <Text level={2}>Speak with an admissions counselor</Text>
              <CTA to="/prep-admissions" mt="3.6rem">
                Book a call
              </CTA>
            </Box>
          </Width>
        </Box>
        {/* Fretboard Fluency */}
        <Box bg={color.amethyst} pb="5em" pt="6rem" variant="slopeBottomRight">
          <Width
            display={["block", null, "flex", null]}
            justifyContent="space-between"
            color={color.white}
          >
            <Box width={["100%", null, 5 / 12, null]}>
              <SmallWave
                display="block"
                mb="1.2rem"
                mx={["auto", null, 0, null]}
              />
              <Heading
                level={2}
                mb="2rem"
                textAlign={["center", null, "left", null]}
                color={color.thulite}
              >
                A clear path from beginner to <br />
                the intermediate level
              </Heading>
              <Text
                mb="2.4rem"
                textAlign={["center", null, "left", null]}
                width={["100%", "80%", "100%", null]}
                mx={["auto", null, 0, null]}
              >
                Receive expert instruction and focused exercises designed by
                professional guitarists to{" "}
                <em>maximize your understanding of the fundamentals.</em>
              </Text>
              <Box
                maxWidth={["30rem", null, "80%", null]}
                mx={["auto", null, 0, null]}
                mb={["3.6rem", null, 0, null]}
              >
                <Img
                  fluid={data.curriculumImage.childImageSharp.fluid}
                  alt="Curriculum Mastery"
                />
              </Box>
            </Box>
            <Box
              width={["100%", "80%", 6 / 12, null]}
              mx={["auto", null, 0, null]}
              textAlign={["center", null, "left", null]}
            >
              <Heading as="h4" level={3} color={color.thulite}>
                Master the Fundamentals
              </Heading>
              <Text level={2} mb="3.6rem">
                Sonora Prep is a concentrated preparation program in Guitar
                Fundamentals - students learn basic theory, open chords, right
                hand technique, barre chords, reading tabs/chord sheets, and
                basic scales.
              </Text>
              <Heading as="h4" level={3} color={color.thulite}>
                Prepare yourself for the Sonora Core Program
              </Heading>
              <Text level={2} mb="3.6rem">
                Prep will get you up to speed quickly, so that you feel
                confident and ready to take on the Sonora Core Program, where
                you'll attain full creative fluency on the instrument.
              </Text>
              <Heading as="h4" level={3} color={color.thulite}>
                Have fun, make music!
              </Heading>
              <Text level={2}>
                The program supports you by focusing on both your musical and
                personal development. You’ll learn to trust yourself as a
                guitarist by becoming comfortable with your fundamentals and
                making music from day one.
              </Text>
            </Box>
          </Width>
          <Box width="100%" mx="auto" mb="3em" textAlign="center">
            <CTA to="/prep-application" variant="tertiary" mt="3.6rem">
              Register for Prep
            </CTA>
          </Box>
        </Box>

        {/* Grow With Our Community */}
        {/* <Width pt="3.6rem">
          <Box
            width={["100%", 10 / 12, 8 / 12, 8 / 12]}
            mx="auto"
            textAlign="center"
            variant="slopeBottomRight"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading level="2" mb="2rem">
              Course Syllabus
            </Heading>
            <Text mb="2em">
              Within 6 weeks, you will build a solid understanding of the
              fundementals, learn 6 songs and preform for a small group of your
              peers.
            </Text>
            <Width
              display={["block", null, "flex", null]}
              justifyContent="space-between"
              mr="1em"
            >
              <Box
                width={["100%", null, 6 / 12, null]}
                textAlign={["center", null, "center", null]}
              >
                <Heading as="h4" level={3} color="amethyst">
                  Week 1 - Foundations
                </Heading>
                <Text level={2} mb="3.6rem">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris
                </Text>
                <Heading as="h4" level={3} color="amethyst">
                  Week 2 - TBD
                </Heading>
                <Text level={2} mb="3.6rem">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris.
                </Text>
                <Heading as="h4" level={3} color="amethyst">
                  Week 3 - TBD
                </Heading>
                <Text level={2}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris.
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 6 / 12, null]}
                mx={["auto", null, 0, null]}
                textAlign={["center", null, "center", null]}
              >
                <Heading as="h4" level={3} color="amethyst">
                  Week 4 - TBD
                </Heading>
                <Text level={2} mb="3.6rem">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris
                </Text>
                <Heading as="h4" level={3} color="amethyst">
                  Week 5 - TBD
                </Heading>
                <Text level={2} mb="3.6rem">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris.
                </Text>
                <Heading as="h4" level={3} color="amethyst">
                  Week 6 - TBD
                </Heading>
                <Text level={2}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris.
                </Text>
              </Box>
            </Width>
            <CTA to="/prep-admissions" variant="primary" mt="3.6rem">
              Register for Prep
            </CTA>
          </Box>
        </Width> */}
        <Box
          color="white"
          position="relative"
          zIndex="3"
          top="0"
          width="100%"
          display="flex"
          flexDirection="column"
          mt="-3.6em"
          textAlign="center"
          backgroundImage="linear-gradient(79.88deg, #1B0646 27.59%, #5128AE 100.28%)"
          pt={["12.5rem", null, null, "7rem"]}
          pb={["4.8rem", null, null, "6rem"]}
          variant="slopeTopLeft"
        >
          <Width
            display="flex"
            flexDirection={["column", null, "row", null]}
            textAlign="left"
            alignItems="center"
            flex="1"
          >
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              borderRadius=".8rem"
              width={["100%", "80%", 6 / 12, null]}
              mx="auto"
            >
              <Box
                width={"100%"}
                borderRadius=".6rem"
                textAlign="center"
                mb={["0rem", null, "0"]}
                bg={color.opal}
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  width={"100%"}
                  p="2.4rem"
                  borderRadius=".6rem"
                  textAlign="center"
                  mb={"0rem"}
                >
                  <Heading
                    mb=".8rem"
                    mx={["auto", 0, null, null]}
                    color={color.tanzanite}
                    textAlign="center"
                  >
                    Sonora Prep Program
                  </Heading>
                  <Text
                    textAlign="center"
                    color={color.tanzanite}
                    fontSize="19px"
                    mb="2.3rem"
                  >
                    10-Week Course for Beginners
                  </Text>
                  <Box textAlign="left">
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        Structured curriculum
                      </ListItem>
                      <ListItem color="tanzanite">
                        Weekly live classes with our mentor staff
                      </ListItem>
                      <ListItem color="tanzanite">
                        Unlimited support and guidance
                      </ListItem>
                      <ListItem color="tanzanite">
                        Structured assignments and projects
                      </ListItem>
                      <ListItem color="tanzanite">Facebook Community </ListItem>
                      {/* <ListItem color="tanzanite">
                        {" "}
                        $500 Tuition Credit for Sonora Guitar Intensive
                        Intermediate Program
                      </ListItem> */}
                    </List>
                  </Box>
                  <CTA
                    variant="special"
                    to="/prep-application"
                    mb="1.2rem"
                    mt="3rem"
                  >
                    Register for Prep
                  </CTA>
                </Box>
                <Box
                backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                color="white"
                py="2rem"
                borderBottomRightRadius=".6rem"
                borderBottomLeftRadius=".6rem"
              >
                  {/* <>
                    <Box
                      display="flex"
                      mb="0.6rem"
                      alignItems="center"
                      justifyContent={["center", null]}
                    >
                      <h4>
                        <SmallNumber>$500</SmallNumber>
                      </h4>
                    </Box>
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.white}
                      mb=".6rem"
                      textAlign="center"
                    >
                      LIMITED TIME
                    </Text>
                  </> */}
                <Box
                  display="flex"
                  mb="0.6rem"
                  alignItems="center"
                  justifyContent={["center", null]}
                >
                  <h4>
                    <Dollar>$ </Dollar>
                    <Number>
                      500
                    </Number>{" "}
                  </h4>
                </Box>
              </Box>
              </Box>
            </Box>
          </Width>
          <Box
            width={["80%", "80%", 6 / 12, 5 / 12]}
            p="2.4rem"
            bg="#FFF7F4"
            borderRadius=".6rem"
            mt="2em"
            mx="auto"
          >
            <Box
              display="flex"
              mb="0.6rem"
              justifyContent="space-between"
              flexDirection={["column", null, "row", null]}
            >
              <Box
                width={["100%", "80%", null, 8 / 12]}
                textAlign={["center", null, "left", null]}
                mb={["1em", null, "0em", null]}
              >
                <Text fontWeight="bold" color="amethyst">
                  Sonora Prep FAQ
                </Text>
                <Text level={2} color="tanzanite">
                  Have questions about our prep program?
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                display="flex"
                alignItems="center"
                justifyContent={["center", null, "flex-end", null]}
              >
                <CTA to="/prep-faq" variant="primary">
                  Read more
                </CTA>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Number = styled.span`
  font-size: 4.2rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const Dollar = styled.span`
  font-weight: 300;
  font-size: 3.4rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: rgba(179, 168, 203, 0.5);
`

const SmallNumber = styled.span`
  font-size: 2.2rem;
  text-decoration: line-through;
  line-height: 1.16;
  opacity: 0.5;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const Extra = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.16;
  color: ${({ theme }) => theme.colors.purpley};
`
